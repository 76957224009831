/*------------------------------------------------------------------
[Color2 Stylesheet]

Project        :   Bizdire - Business Directory and classifieds premium html5 CSS template
Version        :   V.1
Create Date    :   19/02/20
Last Update    :   19/02/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support		   :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/
@import "../scss/variables";
a {
    &:hover {
        color: $primary2;
    }

    &:focus {
        text-decoration: none;
    }
}

.bg-background2:before,
.pattern:before {
    background: linear-gradient(87deg, rgba(0, 87, 165, 0.85), rgba(11, 4, 173, 0.85) 100%) !important;
}

.owl-controls .owl-page.active {
    border-color: $secondary2;
    opacity: 1;

    &:hover {
        border-color: $secondary2;
        opacity: 1;
    }
}

.owl-carousel:hover .owl-nav button {
    color: $secondary2 !important;
}

.owl-nav button {
    color: $primary2 !important;
}

a.bg-primary {

    &:hover,
    &:focus {
        background-color: $secondary2 !important;
    }
}

button.bg-primary {

    &:hover,
    &:focus {
        background-color: $secondary2 !important;
    }
}

.item-card7-overlaytext h4 {
    background: $secondary2;
}

.bg-background-color:before {
    background: linear-gradient(87deg, rgba(0, 87, 165, 0.85), rgba(11, 4, 173, 0.85) 100%) !important;
}

.bg-dark-purple {
    background-color: #19181f !important;
}

.btn-secondary {
    color: $white !important;
    background-color: $secondary2;
    border-color: $secondary2;

    &:hover {
        color: $white;
        background-color: #ef237c;
        border-color: #ef237c;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 2px rgba(255, 43, 136, 0.5);
        background-color: $secondary2;
        border-color: $secondary2;
    }

    &.disabled,
    &:disabled {
        color: $white;
        background-color: $secondary2;
        border-color: $secondary2;
    }

    &:not(:disabled):not(.disabled) {

        &:active,
        &.active {
            color: $white;
            background-color: $secondary2;
            border-color: $secondary2;
        }
    }
}

.show>.btn-secondary.dropdown-toggle {
    color: $white;
    background-color: $secondary2;
    border-color: $secondary2;
}

.btn-secondary:not(:disabled):not(.disabled) {

    &:active:focus,
    &.active:focus {
        box-shadow: 0 0 0 2px rgba(233, 30, 99, 0.1);
    }
}

.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(233, 30, 99, 0.1);
}

.btn-outline-secondary {
    color: $secondary2;
    background-color: transparent;
    background-image: none;
    border-color: $secondary2 !important;

    &:hover {
        color: $white;
        background-color: $secondary2;
        border-color: $secondary2 !important;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 2px rgba(5, 117, 230, 0.1);
    }

    &.disabled,
    &:disabled {
        color: $secondary2;
        background-color: transparent;
    }

    &:not(:disabled):not(.disabled) {

        &:active,
        &.active {
            color: $white;
            background-color: $secondary2;
            border-color: $secondary2;
        }
    }
}

.show>.btn-outline-secondary.dropdown-toggle {
    color: $white;
    background-color: $secondary2;
    border-color: $secondary2;
}

.btn-outline-secondary:not(:disabled):not(.disabled) {

    &:active:focus,
    &.active:focus {
        box-shadow: 0 0 0 2px rgba(5, 117, 230, 0.1);
    }
}

.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(5, 117, 230, 0.1);
}

.btn-link {
    color: $primary2;
}

.alert-secondary {
    color: #fcfdff;
    background-color: $secondary2;
    border-color: $secondary2;

    hr {
        border-top-color: #b7cded;
    }

    .alert-link {
        color: #172b46;
    }
}

.label-secondary {
    background-color: $secondary2;
}

.badge-secondary {
    color: $white;
    background-color: $secondary2;

    &[href] {

        &:hover,
        &:focus {
            color: $white;
            text-decoration: none;
            background-color: $secondary2;
        }
    }
}

.bg-gradient-secondary {
    background: linear-gradient(87deg, rgb(0, 87, 165), rgb(11, 4, 173) 100%) !important;
}

.bg-secondary {
    background-color: $secondary2 !important;
}

a.bg-secondary {

    &:hover,
    &:focus {
        background-color: $secondary2 !important;
    }
}

button.bg-secondary {

    &:hover,
    &:focus {
        background-color: $secondary2 !important;
    }
}

.border-secondary {
    border-color: $secondary2 !important;
}

.text-secondary {
    color: $secondary2 !important;
}

a.text-secondary {

    &:hover,
    &:focus {
        color: #ef237c !important;
    }
}

.social-box.linkedin i {
    background: $secondary2;
}

.checkbox span:after,
.user-card .card-block .activity-leval li.active {
    background-color: $secondary2;
}

#count-down .clock-presenter .digit {
    background: $secondary2;
}

.masthead .masthead-bg {
    background: linear-gradient(120deg, #0f75ff 0%, $secondary2 100%);
}

.notifyimg {
    background: $secondary2;
}

.drop-icon-wrap .drop-icon-item:hover {
    color: #605e7e;
}

.dropdown-item {

    &:hover,
    &:focus,
    &.active,
    &:active {
        color: #605e7e;
    }
}

.timeline__item:after {
    border: 6px solid $secondary2;
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: $primary2;
}

.custom-checkbox .custom-control-input {
    &:checked~.custom-control-label::before {
        background-color: $primary2;
    }

    &:indeterminate~.custom-control-label::before {
        background-color: $secondary2;
    }
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: $primary2;
}

.custom-select:focus {
    border-color: $secondary2;
}

.custom-file-input:focus~.custom-file-label {
    border-color: $secondary2;

    &::after {
        border-color: $secondary2;
    }
}

.custom-file-label::after {
    background-color: $primary2;
    border-left: 1px solid $primary2;
}

.custom-range {

    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb {
        background-color: $secondary2;
    }
}

.nav-link.active {
    background-color: $secondary2;
}

.nav-pills {

    .nav-link.active,
    .show>.nav-link {
        background-color: $secondary2;
    }
}

.page-link:hover {
    color: $secondary2;
}

.page-item.active .page-link {
    background-color: $secondary2;
    border-color: $secondary2;
}

.range.range-secondary {
    input[type="range"] {

        &::-webkit-slider-thumb,
        &::-moz-slider-thumb {
            background-color: $secondary2;
        }
    }

    output {
        background-color: $secondary2;
    }

    input[type="range"] {
        outline-color: $secondary2;
    }
}

.nav-tabs .nav-item1 .nav-link {

    &:hover:not(.disabled),
    &.active {
        background: $secondary2;
    }
}

.panel.price>.panel-heading {
    background: $secondary2;
    color: $white;
    background: $secondary2;
}

.heading-secondary {
    background-color: $secondary2;
}

.page-header .breadcrumb-item a {
    color: #605e7e;
}

.breadcrumb-item1 a:hover,
.panel-title1 a {
    color: $secondary2;
}

.header-bg {
    background: #eef2f9;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #eef2f9, $secondary2);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #eef2f9, $secondary2);
}

.nav-tabs .nav-link {
    &:hover:not(.disabled) {
        color: $white;
        background: $secondary2;
    }

    &.active {
        background: #efeff5;
    }
}

.navtab-wizard.nav-tabs .nav-link {

    &.active,
    &:hover {
        color: $secondary2;
        background: $white;
    }
}

.admin-navbar {
    .nav-item.active .nav-link {
        color: $secondary2;

        .icon {
            color: $secondary2;
        }
    }

    .nav-link {

        &:hover,
        &:focus,
        &.active {
            color: $secondary2;
        }
    }

    .mega-dropdown .sub-item .section-label {
        color: $secondary2;
    }

    .sub-item ul a {
        &:active {
            background-color: $secondary2;
        }

        &:hover,
        &:focus {
            color: $secondary2;
            text-decoration: none;
            background-color: #f9faff;
        }
    }
}

.expanel-secondary {
    border: $secondary2 !important;

    >.expanel-heading {
        color: $white !important;
        background-color: $secondary2 !important;
        border-color: $secondary2 !important;
    }
}

.error-img .form-control:focus {
    color: $white;
    background-color: $white-2;
    border-color: $secondary2;
}

.avatar {
    background: $secondary2 no-repeat center/cover;
}

.spinner {
    background: linear-gradient(120deg, $secondary2 0%, $secondary2 100%);
}

.spinner-lg {
    background-color: $secondary2;
}

.double-bounce1,
.double-bounce2,
.cube1,
.cube2 {
    background: linear-gradient(120deg, $secondary2 0%, $secondary2 100%);
}

.lds-heart div {
    background: $secondary2;

    &:after,
    &:before {
        background: $secondary2;
    }
}

.lds-ring div {
    border: 6px solid $secondary2;
    border-color: $secondary2 transparent transparent transparent;
}

.lds-hourglass:after {
    border: 26px solid $secondary2;
    border-color: $secondary2 transparent;
}

.mediaicon {
    border: 1px solid $secondary2;
    background: $secondary2;
}

a.chip:hover {
    background-color: $secondary2;
}

.highlight .s {
    color: $secondary2;
}

.selectgroup-input {
    &:checked+.selectgroup-button {
        border-color: $secondary2;
        z-index: 1;
        color: $secondary2;
        background: $white;
    }

    &:focus+.selectgroup-button {
        border-color: $secondary2;
        z-index: 2;
        color: $secondary2;
        box-shadow: 0 0 0 2px rgba(11, 4, 173, 0.25);
    }
}

.custom-switch-input {
    &:checked~.custom-switch-indicator {
        background: $primary2;
    }
}

.imagecheck-input:focus~.imagecheck-figure {
    border-color: $secondary2;
    box-shadow: 0 0 0 2px rgba(11, 4, 173, 0.25);
}

.imagecheck-figure:before {
    background: $secondary2 url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
    color: $white;
}

.colorinput-input:focus~.colorinput-color {
    border-color: $secondary2;
    box-shadow: 0 0 0 2px rgba(11, 4, 173, 0.25);
}

.ui-datepicker .ui-datepicker-title {
    color: $secondary2;
}

#back-to-top {
    background: $secondary2;

    &:hover {
        background: $white;
        color: $secondary2;
        border: 2px solid $secondary2;
    }
}

#chat-message {
    background: linear-gradient(87deg, rgba(0, 87, 165, 0.85), rgba(11, 4, 173, 0.85) 100%) !important;
}

.tabs-menu ul li .active {
    background: $secondary2;
    border: 1px solid $secondary2;
}

.tabs-menu1 ul li .active {
    border: 1px solid $secondary2;
    color: $secondary2;
}

.profile-user-tabs li a.active {
    background: #f7f7fb;
    border: 1px solid #e0e5f3;
    color: #605e7e;
    border-radius: 3px;
}

.wideget-user-tab .tabs-menu1 ul li .active {
    background: $white;
    border: 1px solid #e0e5f3;
    border-bottom: 0;
}

.rating-stars .rating-stars-container .rating-star {

    &.is--active .fa-heart,
    &.is--hover .fa-heart {
        color: $secondary2;
    }
}

.message-feed:not(.right) .mf-content {
    background: $secondary2;
    color: $white;

    &:before {
        border-right-color: $secondary2;
    }
}

.msb-reply button {
    background: $secondary2;
}

.wizard-card {
    .moving-tab {
        background-color: $secondary2 !important;
    }

    .choice {

        &:hover .icon,
        &.active .icon {
            border-color: $secondary2 !important;
            color: $secondary2 !important;
        }
    }
}

.cal1 .clndr {
    .clndr-table {
        .header-days {
            background: $secondary2;
        }

        tr .day {

            &.today.event,
            &.my-today.event {
                background: $secondary2;
            }

            &.today:hover,
            &.my-today:hover {
                background: $secondary2;
                color: $white;
            }
        }
    }

    .clndr-controls .clndr-control-button {
        .clndr-previous-button {
            color: $secondary2;
            background: #e6f2fe;
        }

        .clndr-next-button {
            color: $secondary2;
            background: #e6f2fe;

            &:hover {
                background: $secondary2;
            }
        }

        .clndr-previous-button:hover {
            background: $secondary2;
        }
    }
}

.fc button {
    background: $secondary2;
}

.fc-event,
.fc-event-dot {
    background-color: $secondary2;
    color: #f1f1f1 ! important;
}

#sidebar {
    .accordion-toggle i {
        background: linear-gradient(120deg, #0f75ff 60%, $secondary2 100%);
    }

    ul li.active>a {
        color: $secondary2;

        &:hover {
            color: $secondary2;
        }
    }

    li a[aria-expanded="true"].active:before {
        background: linear-gradient(120deg, #0f75ff 60%, $secondary2 100%);
    }

    ul li a:hover,
    .collapse li a:hover {
        color: $secondary2;
    }
}

.sweet-alert button {
    background-color: $secondary2 !important;
}

.timeline__item:after {
    border: 6px solid $secondary2;
}

.label-secondary.arrowed:before {
    border-right-color: $secondary2;
}

.widgetdate p,
.widgetbox p,
.datebox p {
    border-bottom: 2px solid $secondary2;
}

.arrow-ribbon.bg-secondary:before {
    border-left: 15px solid $secondary2;
}

.arrow-ribbon2:before {
    border-left: 24px solid $secondary2;
}

.badge-offer.bg-secondary:after,
.badge-offer1.bg-secondary:after {
    border-top: 12px solid $secondary2;
}

.social-icons li:hover {
    background: $secondary2;
    color: $white;
}

.social li a:hover,
.footer-main a:hover,
.footer-links a:hover {
    color: $secondary2;
}

.product-tags li a:hover {
    background: $secondary2;
    border-color: $secondary2;
}

.info .counter-icon {
    border: 1px solid $white-6;

    i {
        color: $white;
    }
}

.counter-icon {
    border: 1px solid $secondary2;

    i {
        color: $secondary2;
    }
}

.header-links li a {

    &.active,
    &:hover {
        background: $secondary2;
        color: $white;
    }
}

.card-pay .tabs-menu li a.active {
    background: #f7f7fb;
}

.item1-tabs-menu ul li .active {
    background: $secondary2;
}

.items-blog-tab-heading .items-blog-tab-menu li .active {
    background: $secondary2;
    border: 1px solid $secondary2;
    color: $white;
}

.item2-gl-nav .item2-gl-menu li .active {
    color: $secondary2;
}

.item-all-cat {
    .item-all-card:hover .item-all-text h5 {
        color: $secondary2 !important;
    }

    .category-type .item-all-card img {
        background: linear-gradient(120deg, $primary2 0%, $primary2 100%);
    }

    .item-all-card {
        position: relative;
        padding: 1.5rem 1.5rem;
        border-radius: 4px;
        margin-bottom: 10px;
        color: $white;
    }

    .category-type .item-all-card img {
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        padding: 2.3rem 0;
    }

    .row .item-all-card {
        margin-bottom: 1.5rem;
    }

    .item-all-card a {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

.iteam-all-icon i {
    font-size: 1.5rem;
}

.item-all-card img.imag-service {
    width: 40%;
}

.carousel-indicators li.active {
    color: $white;
    background: $secondary2;
}

.item-cards7-ic.realestate-list li a:hover {
    color: $secondary2;
}

.item1-links a {
    &:hover {
        color: $secondary2;
    }

    &.active {
        background: #f6f6fb;
        border-right: 2px solid $secondary2;
    }
}

.settings-tab .tabs-menu li a.active {
    background: #f7f7fb;
}

.ads-tabs .tabs-menus ul li .active {
    background: $secondary2;
    color: $white;
    border-radius: 4px;
    border: 1px solid $secondary2;
}

.showmore-button-inner {
    border: 1px solid $secondary2;
    color: $secondary2;
}

.owl-nav button,
.owl-carousel:hover .owl-nav button {
    color: $primary2 !important;
}

.owl-nav>div i {
    color: $secondary2;
}

.tabs-menu ul.eductaional-tabs li .active {
    background: $secondary2;
}

.register-right .nav-tabs .nav-link:hover {
    background: #efeff5;
    color: #495057;
}

.gradient-icon {
    background: linear-gradient(120deg, #3ba2ff 0%, $secondary2 100%);
}

.axgmap-img {
    border: 3px solid $secondary2;
}

.horizontalMenu>.horizontalMenu-list>li {
    > {
        .horizontal-megamenu .menu_form input {

            &[type="submit"],
            &[type="button"] {
                background-color: $secondary2;
                color: $white;
            }
        }

        a.active {
            background-color: rgba(255, 255, 255, 0.15);
            color: $white;
        }
    }

    &:hover>a {
        background-color: $secondary2;
        color: $white !important;

        .fa {
            background-color: $secondary2;
            color: $white !important;
        }
    }

    >ul.sub-menu>li {
        &:hover>a {
            background-color: $secondary2;
            color: $white;
        }

        >ul.sub-menu>li {

            &:hover>a,
            >ul.sub-menu>li:hover a {
                background-color: $secondary2;
                color: $white;
            }
        }
    }
}

code {
    color: $primary2;
}

#gdpr-cookie-message {

    h4,
    h5 {
        color: $secondary2;
    }

    a {
        color: $secondary2;
        border-bottom: 1px solid $secondary2;

        &:hover {
            border-bottom: 1px solid $secondary2;
            transition: all 0.3s ease-in;
            color: $secondary2;
        }
    }
}

button#gdpr-cookie-advanced {
    color: $white;
    background-color: $secondary2 !important;
}

.ui-widget-header,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    background: $secondary2;
}

.side-menu li a.active:before {
    background: linear-gradient(120deg, $secondary2 60%, #2ddcd3 100%);
    box-shadow: 0px 0px 14px 0px $secondary2;
}

.app-sidebar {
    .side-menu__item.active {
        color: $primary2 !important;

        &:hover {
            color: $primary2 !important;
        }
    }

    .side-menu li {

        &.active>a,
        a.active {
            color: $primary2;
        }
    }

    .slide-menu li.active>a {
        color: $primary2;
    }

    .side-menu li {

        ul li a:hover,
        a:hover,
        &.active>a,
        a.active {
            color: $primary2;
        }
    }

    .slide-menu li.active>a,
    .side-menu li a:hover {
        color: $primary2;
    }
}

.side-menu li a.active:before {
    background: linear-gradient(120deg, $secondary2 60%, #2ddcd3 100%);
    box-shadow: 0px 0px 14px 0px $secondary2;
}

.navtab-wizard.nav-tabs .nav-link {

    &.active,
    &:hover {
        color: #605e7e;
        background: #f7f7fb;
    }
}

.form-inline .nav-search .btn {
    border: 2px solid $primary2;
    font-size: 20px;
    line-height: 24px;
}

.wrapper>h1 span {
    border-bottom: 2px solid $secondary2;
    color: $secondary2;
}

.tab_wrapper {
    >ul li.active {
        border-color: $secondary2;
        background: $secondary2;
        color: $white;
    }

    .controller span {
        background: $secondary2;
    }

    .content_wrapper .accordian_header.active {
        color: $secondary2;

        &:after {
            background: $secondary2;
        }

        .arrow {
            border-top: 3px solid $secondary2;
            border-left: 3px solid $secondary2;
        }
    }

    &.left_side>ul li.active:after,
    &.right_side>ul li.active:after {
        background: $secondary2;
    }
}

.addui-slider .addui-slider-track {

    .addui-slider-range,
    .addui-slider-handle:after {
        background: $secondary2;
    }
}

.accordionjs .acc_section.acc_active>.acc_head {
    background: $secondary2;
}

.perfect-datetimepicker tbody td.selected {
    border: 1px solid $secondary2;
    background-color: $secondary2;
}

div.conv-form-wrapper div {
    &.options div.option {
        border: 1px solid $secondary2;
        color: $secondary2;
    }

    &#messages div.message.from {
        background: $secondary2;
    }

    &.options div.option.selected {
        background: $secondary2;
        color: $white;
    }
}

form.convFormDynamic button.submit {
    border: 1px solid $secondary2;
    background: $secondary2;

    &:hover {
        background: $secondary2;
        color: $white;
    }
}

.exzoom {
    .exzoom_nav .exzoom_nav_inner span.current {
        border: 1px solid $secondary2;
    }

    .exzoom_btn a {
        /*border: 1px solid $secondary2   ;*/
        color: $secondary2;
    }
}

.prev:hover,
.next:hover {
    color: $secondary2 !important;
}

.g_f-s {
    .prev:hover i {
        transform: translate(-5px, 0px);
        color: $secondary2;
    }

    .next:hover i {
        transform: translate(5px, 0px);
        color: $secondary2;
    }

    .close-button>*:hover {
        color: $secondary2;
    }
}

.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a:hover {
    color: $secondary2;
}

.register {
    background: linear-gradient(120deg, #4f4ac7 0%, $secondary2 100%);
}

.btnRegister {
    background: $secondary2;
}

.register .nav-tabs .nav-link.active {
    color: $secondary2;
    border: 1px solid $secondary2;
}

.pretty {

    input:checked~.state.p-secondary label:after,
    &.p-toggle .state.p-secondary label:after {
        background-color: $secondary2 !important;
    }

    input:checked~.state.p-secondary-o label:before,
    &.p-toggle .state.p-secondary-o label:before {
        border-color: $secondary2;
    }

    input:checked~.state.p-secondary-o {

        .icon1,
        .svg,
        svg {
            color: $secondary2;
            stroke: $secondary2;
        }
    }

    &.p-toggle .state.p-secondary-o {

        .icon1,
        .svg,
        svg {
            color: $secondary2;
            stroke: $secondary2;
        }
    }

    &.p-default:not(.p-fill) input:checked~.state.p-secondary-o label:after {
        background-color: $secondary2 !important;
    }

    &.p-switch {
        input:checked~.state.p-secondary:before {
            border-color: $secondary2;
        }

        &.p-fill input:checked~.state.p-secondary:before {
            background-color: $secondary2 !important;
        }
    }
}

.owl-carousel button.owl-dot {
    background: rgba(0, 87, 165, 0.1) !important;

    &.active {
        background: $primary2 !important;
    }
}

.bg-background {
    &:before {
        background: linear-gradient(87deg, rgba(0, 87, 165, 0.85), rgba(11, 4, 173, 0.85) 100%) !important;
    }

    &.bg-background-video:before {
        background: linear-gradient(135deg, rgba(0, 87, 165, 0.5) 0%, rgba(60, 72, 187, 0.5) 100%);
    }
}

.bg-background2 {
    &:before {
        background: linear-gradient(87deg, rgba(0, 87, 165, 0.85), rgba(11, 4, 173, 0.85) 100%) !important;
    }

    &.white-transparent:before {
        background: $black-5 !important;
    }
}

.bg-background-color:before,
.bg-background3:before,
.bg-background-5:before,
.bg-background-6:before,
.slider-header:before {
    background: linear-gradient(87deg, rgba(0, 87, 165, 0.85), rgba(11, 4, 173, 0.85) 100%) !important;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    box-shadow: 0 0 0 2px rgba(11, 4, 173, 0.25);
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(11, 4, 173, 0.25);
}

.custom-file-input:focus~.custom-file-label {
    box-shadow: 0 0 0 2px rgba(11, 4, 173, 0.25);
}

.custom-range {

    &::-webkit-slider-thumb:focus,
    &::-moz-range-thumb:focus,
    &::-ms-thumb:focus {
        outline: none;
        box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(11, 4, 173, 0.25);
    }
}

.error-img .form-control:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(11, 4, 173, 0.25);
}

.selectize-input.focus {
    border-color: $blue;
    box-shadow: 0 0 0 2px rgba(11, 4, 173, 0.25);
}

.item-card7-imgs:hover a {
    background: linear-gradient(-225deg, rgba(0, 87, 165, 0.7) 0%, rgba(121, 24, 242, 0.7) 48%, rgba(172, 50, 228, 0.7) 100%);
}

.app-sidebar .side-menu__item.active {
    background: #f7f7fb !important;

    &:hover {
        background: #f7f7fb !important;
    }
}

.app-sidebar-footer {
    background: linear-gradient(87deg, rgba(0, 87, 165, 0.85), rgba(11, 4, 173, 0.85) 100%);
}

.app-header-background {
    background: linear-gradient(87deg, rgb(0, 87, 165), rgb(11, 4, 173) 100%);

    &.app-header-background2 {
        background: linear-gradient(87deg, rgb(0, 87, 165), rgb(11, 4, 173) 100%);
    }
}

.masthead .masthead-bg {
    background: linear-gradient(87deg, rgba(0, 87, 165, 0.85), rgba(11, 4, 173, 0.85) 100%) !important;
}

@media (max-width: 992px) {
    .search-show .nav-search {
        background: linear-gradient(87deg, rgba(0, 87, 165, 0.85), rgba(11, 4, 173, 0.85) 100%);
    }
}

@media (max-width: 992px) {
    .nav-item.with-sub .sub-item {
        border-top: 2px solid $secondary2;
    }
}

@media (max-width: 480px) {
    .zoom-container .arrow-ribbon2:before {
        border-top: 17px solid transparent;
        border-left: 17px solid $secondary2;
        border-bottom: 17px solid transparent;
    }
}

.bg-primary {
    background-color: $primary2 !important;
}

a.bg-primary {

    &:hover,
    &:focus {
        background-color: $primary2 !important;
    }
}

button.bg-primary {

    &:hover,
    &:focus {
        background-color: $primary2 !important;
    }
}

.btn-primary {
    color: $white;
    background-color: $primary2;
    border-color: $primary2;

    &:hover {
        color: $white;
        background-color: $primary2;
        border-color: $primary2;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 2px rgba(47, 12, 108, 0.5);
    }

    &.disabled,
    &:disabled {
        color: $white;
        background-color: $primary2;
        border-color: $primary2;
    }

    &:not(:disabled):not(.disabled) {

        &:active,
        &.active {
            color: $white;
            background-color: $primary2;
            border-color: $primary2;
        }
    }
}

.show>.btn-primary.dropdown-toggle {
    color: $white;
    background-color: $primary2;
    border-color: $primary2;
}

.btn-primary:not(:disabled):not(.disabled) {

    &:active:focus,
    &.active:focus {
        box-shadow: 0 0 0 2px rgba(47, 12, 108, 0.5);
    }
}

.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(47, 12, 108, 0.5);
}

.bg-linkedin {
    background: #0077b5;
}

.bg-facebook {
    background: #3C5A99;
}

.accent-2 {
    border-top: 2px solid $secondary2;
}

.header-main i {
    color: $secondary2;
}

.item-card {
    .item-card-desc {
        &:before {
            background: rgba(40, 35, 47, 0);
        }

        .item-card-text {
            background: linear-gradient(rgba(40, 35, 47, 0.4) 10%, rgba(40, 35, 47, 0.95));
            transition: all .5s;
        }
    }

    &:hover .item-card-desc .item-card-text {
        background: linear-gradient(rgba(40, 35, 47, 0.5) 10%, rgba(40, 35, 47, 0.95)) !important;
    }
}

@media (min-width: 992px) {
    .sticky-wrapper.is-sticky .horizontalMenu>.horizontalMenu-list>li>a.active {
        background-color: $secondary2;
        color: $white;
    }
}

.btn-orange {
    color: $white;
    background-color: #e67605;
    border-color: #e67605;
}

.item-card7-imgs:hover a {
    background: transparent;
}

.arrow-ribbon.bg-primary:before {
    border-left: 15px solid $primary2;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $secondary2;
    color: $white;
}

.sticky-wrapper.relative.banners .horizontalMenu>.horizontalMenu-list>li {

    >a.active,
    &:hover>a {
        background-color: $secondary2;
    }
}

.pricingTable2.pink .pricing-plans {
    background-color: $secondary2;
}

.pricingTable {
    .title:after {
        border-left: 26px solid rgba(255, 43, 136, 0.1);
    }

    &:hover,
    &.advance-pricing {
        border: 1px solid rgb(255, 43, 136);
    }

    &:hover .title {
        background: $secondary2;
    }
}

.construction-image:before {
    content: '';
    background: rgba(41, 5, 134, 0.88);
}

.construction {
    z-index: 1;
}

.countdown-timer-wrapper .timer .timer-wrapper .time {
    color: $secondary2;
    background: rgb(255, 255, 255);
}

.pricingTable {
    &:hover .pricingTable-signup {
        background: $secondary2;
        color: $white;
    }

    .pricingTable-signup {
        background: rgba(255, 43, 136, 0.12);
        color: $secondary2;
    }

    &:hover .title {
        background: $secondary2;
        color: $white;
    }

    &.advance-pricing {
        border: 1px solid $secondary2;
    }

    &:hover {
        border: 1px solid $secondary2;

        .title {
            &:before {
                border-right-color: $secondary2;
            }

            &:after {
                border-left-color: $secondary2;
            }
        }
    }

    .title {
        &:before {
            content: "";
            border-right: 26px solid rgba(255, 43, 136, 0.1);
        }

        &:after {
            content: "";
            border-right: 26px solid rgba(255, 43, 136, 0.1);
            border-right: none;
            border-left: 26px solid rgba(255, 43, 136, 0.1);
        }

        background: rgba(255,
            43,
            136,
            0.1);
        color: $secondary2;
    }
}

.construction-image:before {
    background: linear-gradient(87deg, rgba(0, 87, 165, 0.85), rgba(11, 4, 173, 0.85) 100%) !important;
}

@media (max-width: 991px) {
    .horizontalMenu>.horizontalMenu-list>li>a.active {
        background-color: $secondary2;
        color: $white;
    }
}

.bg-card-light:hover {
    border-color: $secondary2;
}

.bg-card {
    &:hover {
        background: $secondary2;
        color: $white;
    }

    .cat-img i {
        color: $secondary2;
    }

    &:hover {
        .cat-img i {
            color: $white;
        }

        .bg-secondary {
            background-color: $white !important;
            color: $secondary2 !important;

            i {
                color: $secondary2 !important;
            }
        }
    }
}

.sticky-wrapper.absolute.banners .horizontalMenu>.horizontalMenu-list>li>a.active {
    background-color: $secondary2;
    color: $white !important;
}

.category-svg svg {
    g {
        fill: $primary2;
    }

    fill: $primary2;
}

.logo-svg svg {
    fill: $primary2;

    g {
        fill: $primary2;
    }
}

.service-card-svg svg {
    fill: $primary2;
}

.category-svg {
    &:after {
        background: rgba(0, 87, 165, 0.02);
    }

    background: rgba(0,
        87,
        165,
        0.04);
}

.breadcrumb-item.active,
.perfect-datetimepicker table td.weekend,
.invalid-feedback {
    color: $secondary2;
}

.custom-select.is-invalid,
.form-control.is-invalid {
    border-color: $secondary2;
}

.was-validated {

    .custom-select:invalid,
    .form-control:invalid {
        border-color: $secondary2;
    }
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus {
    border-color: $secondary2;
    box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
}

.was-validated {

    .custom-select:invalid:focus,
    .form-control:invalid:focus {
        border-color: $secondary2;
        box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
    }
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label,
.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
    color: $secondary2;
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: $secondary2;
}

@media (min-width: 992px) {
    .sticky-wrapper.is-sticky .horizontalMenu>.horizontalMenu-list>li>a.active {
        background-color: $secondary2;
        color: $white;
    }
}

.header-btn.has-new:before {
    background: $secondary2;
}

.accent-2 {
    border-top: 2px solid $secondary2;
}

.item-card7-overlaytext h4,
.item-card8-overlaytext h6 {
    background: $secondary2;
}

@media (max-width: 991px) {
    .horizontalMenu>.horizontalMenu-list>li>a.active {
        background-color: $secondary2;
        color: $white;
    }
}

.sticky-wrapper.absolute.banners .horizontalMenu>.horizontalMenu-list>li {

    &:hover>a,
    >a.active {
        background-color: $secondary2;
        color: $white !important;
    }
}

.ribbon-1 {
    &:after {
        border-top: 13px solid $secondary2;
    }

    span {
        background: $secondary2;

        &:before,
        &:after {
            background: $secondary2;
        }
    }
}

.header-main i {
    color: $secondary2;
}

.pricingTable {
    .title {
        background: rgba(233, 30, 99, 0.1);
        color: $secondary2;
    }

    &:hover .title {
        background: $secondary2;
        color: $white;
        box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.2), 0 2px 9px 0 rgba(62, 57, 107, 0.2);
    }

    &.advance-pricing {
        border: 1px solid $secondary2;
    }

    &:hover {
        border: 1px solid $secondary2;

        .title {
            &:before {
                border-right-color: $secondary2;
            }

            &:after {
                border-left-color: $secondary2;
            }
        }
    }

    .pricingTable-signup {
        background: rgba(233, 30, 99, 0.1);
        color: $secondary2;
    }

    &:hover .pricingTable-signup {
        background: $secondary2;
        color: $white;
    }
}

.pricingTable2.pink .pricing-plans {
    background-color: $secondary2;
}

.bg-card-light:hover {
    box-shadow: 0 0 40px 0 rgba(152, 154, 158, 0.5);
    background: $white;
    border-color: $secondary2;
}

.bg-card {
    &:hover {
        box-shadow: 0 0 40px 0 rgba(152, 154, 158, 0.5);
        background: $secondary2;
        color: $white;
    }

    .cat-img i {
        color: $secondary2;
    }
}

.custom-control-input:checked~.custom-control-label::before {
    color: $white;
    border-color: $primary2;
    background-color: $primary2;
    color: $white;
    border-color: $primary2;
    background-color: $primary2;
}

.bg-secondary-transparent {
    background-color: rgba(255, 43, 136, 0.1);
}

.bg-primary-transparent {
    background-color: rgba(0, 87, 165, 0.1);
}

.badge-primary {
    color: $white;
    background-color: $primary2;

    &[href] {

        &:focus,
        &:hover {
            color: $white;
            text-decoration: none;
            background-color: $primary2;
        }
    }
}

.modal-header .close.btn {
    background-color: $primary2;
    border-color: $primary2;
}

.bg-primary {
    background-color: $primary2 !important;
}

a.bg-primary {

    &:focus,
    &:hover {
        background-color: $primary2 !important;
    }
}

button.bg-primary {

    &:focus,
    &:hover {
        background-color: $primary2 !important;
    }
}

.text-primary {
    color: $primary2 !important;
}

a.text-primary {

    &:focus,
    &:hover {
        color: $primary2 !important;
    }
}

.arrow-ribbon.bg-primary:before {
    border-left: 15px solid $primary2;
}

#gdpr-cookie-message button,
button#ihavecookiesBtn {
    background: $primary2;
}

.ribbon-2 {
    &:after {
        border-top: 13px solid $primary2;
    }

    span {
        background: $primary2;

        &:before,
        &:after {
            background: $primary2;
        }
    }
}

.pricingTable2.blue .pricing-plans {
    background-color: $primary2;
}

.btn-primary {
    color: $white !important;
    background-color: $primary2;
    border-color: $primary2;

    &:hover {
        color: $white;
        background-color: $primary2;
        border-color: $primary2;
    }

    &.focus,
    &:focus {
        box-shadow: 0 0 0 2px rgba(0, 87, 165, 0.53);
    }

    &.disabled,
    &:disabled {
        color: $white;
        background-color: $primary2;
        border-color: $primary2;
    }

    &:not(:disabled):not(.disabled) {

        &.active,
        &:active {
            color: $white;
            background-color: $primary2;
            border-color: $primary2;
        }
    }
}

.show>.btn-primary.dropdown-toggle {
    color: $white;
    background-color: $primary2;
    border-color: $primary2;
}

.btn-primary:not(:disabled):not(.disabled) {

    &.active:focus,
    &:active:focus {
        box-shadow: 0 0 0 2px rgba(0, 87, 165, 0.26);
    }
}

.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(0, 87, 165, 0.26);
}

.pattern-1 {
    background: linear-gradient(87deg, rgba(0, 87, 165, 0.85), rgba(11, 4, 173, 0.85) 100%), url(../images/products/products/img04.jpg) center;
    border-radius: 5px;
}

.item-card2-icons a:hover {
    background: $secondary2;
}

.header-style03 .horizontalMenu>.horizontalMenu-list>li>a.active,
.item-card7-icons a:hover {
    background-color: $secondary2;
    color: $white;
}

.header-style-04 {
    background: linear-gradient(87deg, rgb(0, 87, 165), rgb(11, 4, 173) 100%) !important;
}

.xdsoft_autocomplete .xdsoft_autocomplete_dropdown>div.active {
    background: rgba(0, 87, 165, 0.1);
}

.footer-btn-outline:hover {
    border: 1px solid $secondary2;
    background-color: $secondary2;
    color: $white !important;
}

.label-primary {
    background-color: $secondary2;
    color: $white;
}

.label.label-primary {

    &.arrowed-in:before,
    &.arrowed:before {
        border-right-color: $secondary2;
    }
}

.user-tabs ul li a.active {
    background: $primary2;
    color: $white;
}

.alert-primary {
    color: $white-9;
    background-color: $primary2;
    border-color: $primary2;
}

.btn.dropdown-toggle.btn-primary~.dropdown-menu .dropdown-plus-title {
    border-color: $primary2 !important;
}

.sub-newsletter.d-sm-flex i {
    color: $secondary2;
}

.horizontalMenu>.horizontalMenu-list>li> {

    ul.sub-menu>li.active a,
    .horizontal-megamenu .link-list li.active a {
        color: $secondary2;
    }
}